import React, {Fragment, useEffect} from 'react';
import {withRouter, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {getOffices} from '../../actions/offices';
import OfficeItem from './OfficeItem';

const Offices = ({getOffices, offices: {loading, offices}}) => {
  useEffect(() => {
    getOffices();
  }, [getOffices]);

  return loading ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <h5 className='card-title'>Offices</h5>
          <p className='card-text'>
            <b>Manage</b> the <i>Office</i> listed below.
          </p>
          <Link to='/offices-ui/add' className='btn btn-sm btn-primary'>
            Add Item
          </Link>
        </div>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>Offices</th>
            <th scope='col' className='text-center'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {offices.map(office => (
            <OfficeItem key={office._id} office={office} />
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

Offices.propTypes = {
  offices: PropTypes.object.isRequired,
  getOffices: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  offices: state.offices,
});

export default connect(mapStateToProps, {getOffices})(withRouter(Offices));
