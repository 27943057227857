import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {getAllProfiles} from '../../actions/profile';
import {updateTeam} from '../../actions/team';

const AddingTeamMember = ({
  team,
  profiles: {profiles, loading},
  getAllProfiles,
  updateTeam,
}) => {
  useEffect(() => {
    if (!teamMember && !profiles[0]) {
      getAllProfiles();
      setFormData({
        teamMember: loading || !profiles ? '' : profiles[0]._id,
        teamCoordinator: false,
      });
    }
  }, [getAllProfiles, profiles, loading]);

  if (profiles.length > 0) {
    profiles = profiles.filter((profile) => {
      return !profile.date_deleted;
    });
  }

  const [formData, setFormData] = useState({
    teamMember: '',
    teamCoordinator: false,
  });

  const {teamMember, teamCoordinator} = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name == 'teamMember' ? e.target.value : e.target.checked,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    if (formData.teamCoordinator) {
      team.team_coordinator = [...team.team_coordinator, formData.teamMember];
      team.teamCoordinator = team.team_coordinator;
    } else {
      team.team_members = [...team.team_members, formData.teamMember];
      team.teamMembers = team.team_members;
    }
    team.teamName = team.team_name;
    team.teamDesc = team.team_desc;
    updateTeam(team, team._id);
  };

  return loading && profiles ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <h6 className='text-muted card-subtitle'>Manage Team Member</h6>
          <hr />
          <form onSubmit={(e) => onSubmit(e)}>
            <div className='form-group row'>
              <div className='col-sm-12'>
                <select
                  name='teamMember'
                  id='teamMember'
                  className='form-control'
                  value={teamMember}
                  onChange={(e) => onChange(e)}
                >
                  {profiles.map((profile) => (
                    <option key={`${profile._id}`} value={`${profile._id}`}>
                      {profile.name} - {profile.email}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                name='teamCoordinator'
                id='teamCoordinator'
                value={teamCoordinator}
                onChange={(e) => onChange(e)}
              />
              <label className='form-check-label' htmlFor='teamCoordinator'>
                Coordinator
              </label>
            </div>
            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

AddingTeamMember.propTypes = {
  getAllProfiles: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({profiles: state.profiles});

export default connect(mapStateToProps, {
  updateTeam,
  getAllProfiles,
})(AddingTeamMember);
