import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getProjects } from '../../actions/projects';
import { getUserTimelines } from '../../actions/timeline';
// import ClockinDashboard from "./ClockinDashboard";
// import TimelineDashboard from './TimelineDashboard';

import { discToken } from '../../actions/users';

const Dashboard = ({
  getProjects,
  getUserTimelines,
  discToken,
  disc: { token, loadingToken },
  auth: { user, loading },
  projects,
  timelines,
}) => {
  useEffect(() => {
    discToken();
  }, [discToken]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card border mb-4'>
        <div className='card-body'>
          <h5 className='card-title'>Dashboard</h5>
          <h6 className='card-subtitle mb-2  text-muted'>
            Halo {user && user.name}!
          </h6>
          {user && user.name ? (
            <p className='card-text'>
              Use the menu to browse around the system.
            </p>
          ) : (
            <Fragment>
              <p className='card-text text-danger'>
                Please update your profile!
              </p>
              <Link to='/profile' className='btn btn-primary my-1'>
                Update Profile
              </Link>
            </Fragment>
          )}
        </div>
      </div>
      {/* <div className='card-deck'>
        <Link to='/address' className='text-dark'>
          <div className='card border mb-4'>
            <div className='card-body'>
              <h5 className='card-title'>Alamat</h5>
              <h6 className='card-subtitle mb-2  text-muted'>
                Update alamat kamu disini
              </h6>
            </div>
          </div>
        </Link>
        <a className='text-dark' href={config.TAP_URL}>
          <div className='card border mb-4'>
            <div className='card-body'>
              <h5 className='card-title'>PeopleHR Extension</h5>
              <h6 className='card-subtitle mb-2  text-muted'>
                Tap in / Tap out / mark project / cek kuota WFH & Holiday
              </h6>
            </div>
          </div>
        </a>
      </div>
      <div className='card-deck'>
        <Can
          role={user.role}
          perform='peoplem:visit'
          yes={() => (
            <a className='text-dark' href={config.MANAGER_URL}>
              <div className='card border mb-4'>
                <div className='card-body'>
                  <h5 className='card-title'>PeopleHR Manager</h5>
                  <h6 className='card-subtitle mb-2  text-muted'>
                    Check your team WFH, Holiday, work time
                  </h6>
                </div>
              </div>
            </a>
          )}
          no={() => ''}
        />
        <Can
          role={user.role}
          perform='kucing:visit'
          yes={() => (
            <a className='text-dark' href={config.KUCING_URL}>
              <div className='card border mb-4'>
                <div className='card-body'>
                  <h5 className='card-title'>Kucing Menyapa</h5>
                  <h6 className='card-subtitle mb-2  text-muted'>
                    Kucing menyapamu pagi hari
                  </h6>
                </div>
              </div>
            </a>
          )}
          no={() => ''}
        />
        <Can
          role={user.role}
          perform='disc:visit'
          yes={() => (
            <a
              className='text-dark'
              href={`${config.DISC_TEST_URL}?tko=jangansoktau`}
            >
              <div className='card border mb-4'>
                <div className='card-body'>
                  <h5 className='card-title'>DISC Test</h5>
                  <h6 className='card-subtitle mb-2  text-muted'>
                    DISC factors test
                  </h6>
                </div>
              </div>
            </a>
          )}
          no={() => ''}
        />
      </div>
      <div className='card-deck'>
        <a className='text-dark' href={config.XERO_URL}>
          <div className='card border mb-4'>
            <div className='card-body'>
              <h5 className='card-title'>Xero Generator</h5>
              <h6 className='card-subtitle mb-2  text-muted'>
                Xero Description Generator untuk tim account
              </h6>
            </div>
          </div>
        </a>
        <a className='text-dark' href={config.ZENTALK_URL}>
          <div className='card border mb-4'>
            <div className='card-body'>
              <h5 className='card-title'>Zendesk Talk Cleaner</h5>
              <h6 className='card-subtitle mb-2  text-muted'>
                Zendesk Talk data cleaner untuk tim AE
              </h6>
            </div>
          </div>
        </a>
        <Can
          role={user.role}
          perform='cover:visit'
          yes={() => (
            <a
              className='text-dark'
              href={`${config.COVER_URL}?cover=jangansoktau`}
            >
              <div className='card border mb-4'>
                <div className='card-body'>
                  <h5 className='card-title'>Cover</h5>
                  <h6 className='card-subtitle mb-2  text-muted'>
                    Custom Order Turn Over Calculation
                  </h6>
                </div>
              </div>
            </a>
          )}
          no={() => ''}
        />
      </div>
      <div className='card-deck'>
        <div className='card border text-dark mb-4'>
          <div className='card-body'>
            <h5 className='card-title'>Link Shortener</h5>
            <h6 className='card-subtitle mb-2 text-muted'>
              Create something short and recognizable short link
            </h6>
            <ul>
              <li>
                <a className='' href={config.EXT_URL.LINK}>
                  Link Shorterner
                </a>
              </li>
              <li>
                <a className='' href={config.EXT_URL.LINK_AWS}>
                  Link Shorterner - on AWS
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='card-deck'>
        <div className='card border bg-dark text-white mb-4'>
          <div className='card-body'>
            <h5 className='card-title'>Segera datang</h5>
            <h6 className='card-subtitle mb-2  text-muted'>
              Sedang dalam pembuatan
            </h6>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className='card-deck '> 
        <div className='card border bg-danger text-white mb-4'>
          <div className='card-body'>
            <h5 className='card-title'>PPP (Disabled)</h5>
          </div>
        </div>
      </div>*/}
      {/* {<ClockinDashboard key='clockin-dashboard' projects={projects} />} */}
      {/* {<TimelineDashboard key='timeline-dashboard' timelines={timelines} />} */}
    </Fragment>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  projects: PropTypes.object.isRequired,
  disc: PropTypes.object.isRequired,
  // timelines: PropTypes.object.isRequired,
  getProjects: PropTypes.func.isRequired,
  getUserTimelines: PropTypes.func.isRequired,
  discToken: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  projects: state.projects,
  timelines: state.timelines,
  disc: state.disc,
});

export default connect(mapStateToProps, {
  getProjects,
  getUserTimelines,
  discToken,
})(Dashboard);
