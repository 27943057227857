import React, {useState, useEffect, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Map, GoogleApiWrapper, InfoWindow, Marker} from 'google-maps-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getUserAddress, updateUserAddress} from '../../actions/user-address';
import {getUserMeta} from '../../actions/user-metas';
import Spinner from '../layout/Spinner';
import {getOffice} from '../../actions/offices';

const mapStyles = {
  width: '1100px',
  height: '100%',
};

const MapContainer = props => {
  const {
    google,
    user_address: {user_address, loading},
    user_metas: {user_meta},
    users: {user},
    offices: {office},
    getUserAddress,
    updateUserAddress,
    getUserMeta,
    getOffice,
    type,
  } = props;
  // const { user } = users;
  // const { user_meta, loading } = user_metas;

  const [formData, setFormData] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    mobileNumber: '',
    postcode: '',
    address: '',
    distance: '',
    update_state: 'disabled',
    geocode_address: [],
    mapCenter: {
      lat: -7.7205555,
      lng: 110.4032405,
    },
    officeMapCenter: {
      lat: -7.7205555,
      lng: 110.4032405,
    },
  });

  const {
    showingInfoWindow,
    activeMarker,
    selectedPlace,
    mobileNumber,
    postcode,
    address,
    distance,
    geocode_address,
    mapCenter,
    officeMapCenter,
    update_state,
  } = formData;
  // belum bener ini
  useEffect(() => {
    if (
      Object.entries(user).length !== 0 &&
      Object.entries(user_address).length === 0
    ) {
      getUserAddress(user._id);
    }

    if (
      Object.entries(user).length !== 0 &&
      Object.entries(user_meta).length === 0
    ) {
      getUserMeta(user._id);
    }

    if (
      Object.entries(user).length !== 0 &&
      Object.entries(user_meta).length !== 0 &&
      Object.entries(office).length === 0
    ) {
      getOffice(user_meta.office);
    }

    if (
      Object.entries(office).length !== 0 &&
      Object.entries(user_address).length !== 0
    ) {
      setFormData({
        ...formData,
        mobileNumber:
          loading || !user_address.mobileNumber
            ? ''
            : user_address.mobileNumber,
        postcode:
          loading || !user_address.postcode ? '' : user_address.postcode,
        address: loading || !user_address.address ? '' : user_address.address,
        distance:
          loading || !user_address.distance ? '' : user_address.distance,
        update_state: loading || !user_address.distance ? 'disabled' : '',
        mapCenter: {
          lat:
            loading || !user_address.lat ? office.latlng.lat : user_address.lat,
          lng:
            loading || !user_address.lng ? office.latlng.lng : user_address.lng,
        },
        officeMapCenter: {
          lat: !office.latlng.lat ? '' : office.latlng.lat,
          lng: !office.latlng.lng ? '' : office.latlng.lng,
        },
      });
    }
  }, [
    loading,
    user_address,
    user_meta,
    getUserMeta,
    user,
    office,
    getOffice,
    getUserAddress,
  ]);

  let mapDiv = React.createRef();

  const onChange = e => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = e => {
    e.preventDefault();
    // updateUser(formData, user_metas.user_meta.user._id);
    updateUserAddress(formData, user._id);
  };

  const onAddressClick = e => {
    e.preventDefault();
    setFormData({
      ...formData,
      mapCenter: {
        lat: e.currentTarget.dataset.lat,
        lng: e.currentTarget.dataset.lng,
      },
    });
    if (mapDiv.current) {
      if (mapDiv.current) {
        mapDiv.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  };

  const findYourAddress = e => {
    e.preventDefault();
    map_geocode(address, postcode);
  };

  const onMarkerClick = (props, marker, e) => {
    setFormData({
      ...formData,
      showingInfoWindow: true,
      activeMarker: marker,
      selectedPlace: props,
    });
  };

  const onMarkerClose = props => {
    if (showingInfoWindow) {
      setFormData({
        ...formData,
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  const map_geocode = (address, postcode) => {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {address: address + ' ' + postcode},
      (responses, status) => {
        if (status === 'OK') {
          setFormData({
            ...formData,
            geocode_address: responses,
          });
        }
      }
    );
  };

  const map_calculateDistance = LatLng => {
    var destinationLat = new google.maps.LatLng(
      officeMapCenter.lat,
      officeMapCenter.lng
    );
    var originLat = new google.maps.LatLng(LatLng.lat(), LatLng.lng());

    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [originLat],
        destinations: [destinationLat],
        travelMode: 'DRIVING',
        avoidHighways: false,
        avoidTolls: true,
      },
      callback
    );

    function callback(response, status) {
      if (status === 'OK') {
        var origins = response.originAddresses;
        // var destinations = response.destinationAddresses;
        for (var i = 0; i < origins.length; i++) {
          var results = response.rows[i].elements;
          for (var j = 0; j < results.length; j++) {
            var element = results[j];
            if (element.status != 'ZERO_RESULTS') {
              var distance = element.distance.text;
              // var duration = element.duration.text;
              // var from = origins[i];
              // var to = destinations[j];
              formData.lat = LatLng.lat();
              formData.lng = LatLng.lng();
              formData.distance = distance;
              updateUserAddress(formData, user._id);
            }
          }
        }
      }
    }
  };

  const onMapClick = (props, map, e) => {
    if (type === 'editor') {
      map_calculateDistance(e.latLng);
    }
  };

  const parentMapStyles = {
    width: '1100px',
    height: '600px',
    position: 'relative',
  };
  return loading && !office && !user_address ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <form onSubmit={e => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='mobileNumber' className='col-sm-2 col-form-label'>
                Mobile Number
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='mobileNumber'
                  className='form-control'
                  id='mobileNumber'
                  placeholder='Mobile Number'
                  value={mobileNumber}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='address' className='col-sm-2 col-form-label'>
                Address
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='address'
                  className='form-control'
                  id='address'
                  placeholder='Address'
                  value={address}
                  onChange={e => onChange(e)}
                />

                <br />
                {type === 'editor' ? (
                  <button
                    type='button'
                    onClick={e => findYourAddress(e)}
                    className='btn btn-sm btn-info'
                  >
                    find your address
                  </button>
                ) : (
                  ''
                )}
                <hr />
                <ul>
                  {geocode_address.map(item => (
                    <li key={`${item.place_id}`}>
                      <button
                        type='button'
                        className='btn btn-link text-left'
                        onClick={e => onAddressClick(e)}
                        data-key={`${item.place_id}`}
                        data-lat={`${item.geometry.location.lat()}`}
                        data-lng={`${item.geometry.location.lng()}`}
                      >
                        {item.formatted_address} ({' '}
                        {item.geometry.location.lat()} -
                        {item.geometry.location.lng()} )
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='postcode' className='col-sm-2 col-form-label'>
                Postcode
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='postcode'
                  className='form-control'
                  id='postcode'
                  placeholder='Postcode'
                  value={postcode}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='distance' className='col-sm-2 col-form-label'>
                Distance from office
              </label>
              <div className='col-sm-10'>
                <label className='col-form-label font-weight-bold'>
                  {distance}
                </label>
                <small id='distanceHelp' className='form-text text-muted'>
                  *nilai akan keluar ketika sudah memilih titik di map
                </small>
              </div>
            </div>{' '}
            <div className='form-group row'>
              <div className='col-sm-10'>
                {type === 'editor' ? (
                  <button
                    type='submit'
                    className={`btn btn-sm btn-success ${update_state}`}
                    disabled={`${update_state}`}
                  >
                    update
                  </button>
                ) : (
                  ''
                )}
                &nbsp;
                {type === 'viewer' ? (
                  <Link to='/address' className='btn btn-sm btn-secondary'>
                    go back
                  </Link>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className='mb-4'>
        <div className='card-body' ref={mapDiv} style={parentMapStyles}>
          <Map
            google={google}
            zoom={14}
            style={mapStyles}
            initialCenter={officeMapCenter}
            center={mapCenter}
            onClick={onMapClick}
          >
            <Marker
              position={officeMapCenter}
              onClick={onMarkerClick}
              name={'Krafthaus Indonesia'}
              title={'Krafthaus Indonesia'}
            />
            <Marker
              position={{
                lat: user_address.lat,
                lng: user_address.lng,
              }}
              onClick={onMarkerClick}
              name={'Your location'}
              icon={
                'https://cdn.mapmarker.io/api/v1/pin?size=48&background=%2368BC00&icon=fa-star&color=%23FFFFFF&voffset=0&hoffset=1&'
              }
            />
            <InfoWindow
              marker={activeMarker}
              visible={showingInfoWindow}
              onClose={onMarkerClose}
            >
              <div>
                <h4>{selectedPlace.name}</h4>
              </div>
            </InfoWindow>
          </Map>
        </div>
      </div>
    </Fragment>
  );
};

MapContainer.propTypes = {
  getUserAddress: PropTypes.func.isRequired,
  getUserMeta: PropTypes.func.isRequired,
  updateUserAddress: PropTypes.func.isRequired,
  getOffice: PropTypes.func.isRequired,
  user_address: PropTypes.object.isRequired,
  user_metas: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  offices: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user_address: state.user_address,
  user_metas: state.user_metas,
  users: state.users,
  offices: state.offices,
});

const reduxConn = connect(mapStateToProps, {
  updateUserAddress,
  getUserAddress,
  getUserMeta,
  getOffice,
})(MapContainer);
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAFB3Lnv14Qhgvv_fXye1OamWn5CtloD8E',
})(reduxConn);
