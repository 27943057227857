import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import {getUserAddress, updateUserAddress} from '../../actions/user-address';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';

const Profile = ({
  user: {_id},
  getUserAddress,
  updateUserAddress,
  user_address: {loading, user_address},
}) => {
  useEffect(() => {
    if (Object.entries(user_address).length == 0 && _id) {
      getUserAddress(_id);
    }
    setFormData({
      mobileNumber:
        loading || !user_address.mobileNumber ? '' : user_address.mobileNumber,
      postcode: loading || !user_address.postcode ? '' : user_address.postcode,
      address: loading || !user_address.address ? '' : user_address.address,
      distance: loading || !user_address.distance ? '' : user_address.distance,
    });
  }, [getUserAddress, user_address, loading]);
  const [formData, setFormData] = useState({
    mobileNumber: '',
    postcode: '',
    address: '',
    distance: '',
  });

  const {mobileNumber, postcode, address, distance} = formData;
  const onChange = (e) =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = async (e) => {
    e.preventDefault();
    updateUserAddress(formData, _id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-header'>Address</div>
        <div className='card-body'>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='mobileNumber' className='col-sm-2 col-form-label'>
                Mobile Number
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='mobileNumber'
                  className='form-control'
                  id='mobileNumber'
                  placeholder='Mobile Number'
                  value={mobileNumber}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='address' className='col-sm-2 col-form-label'>
                Address
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='address'
                  className='form-control'
                  id='address'
                  placeholder='Address'
                  value={address}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='postcode' className='col-sm-2 col-form-label'>
                Postcode
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='postcode'
                  className='form-control'
                  id='postcode'
                  placeholder='Postcode'
                  value={postcode}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='distance' className='col-sm-2 col-form-label'>
                Distance from office
              </label>
              <div className='col-sm-10'>
                <label className='col-form-label font-weight-bold'>
                  {distance}
                </label>
              </div>
            </div>{' '}
            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

Profile.propTypes = {
  getUserAddress: PropTypes.func.isRequired,
  updateUserAddress: PropTypes.func.isRequired,
  user_address: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user_address: state.user_address,
});

export default connect(mapStateToProps, {
  getUserAddress,
  updateUserAddress,
})(Profile);
