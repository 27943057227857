import {
  GET_OFFICES,
  GET_OFFICE,
  OFFICE_ERROR,
  ADD_OFFICE,
  UPDATE_OFFICE,
  DELETE_OFFICE,
  CLEAR_OFFICE,
} from '../actions/types';

const initialState = {
  office: {},
  offices: [],
  loading: true,
  error: {},
};

export default function(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_OFFICES:
      return {
        ...state,
        office: {},
        offices: payload,
        loading: false,
      };
    case GET_OFFICE:
      return {
        ...state,
        office: payload,
        offices: [],
        loading: false,
      };
    case ADD_OFFICE:
      return {
        ...state,
        office: {},
        offices: [payload, ...state.offices],
        loading: false,
      };
    case UPDATE_OFFICE:
      return {
        ...state,
        office: payload,
        offices: [],
        loading: false,
      };
    case DELETE_OFFICE:
      return {
        ...state,
        office: {},
        offices: state.offices.filter(office => office._id !== payload),
        loading: false,
      };
    case CLEAR_OFFICE:
      return {
        ...state,
        office: {},
        offices: [],
        loading: false,
      };
    case OFFICE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
