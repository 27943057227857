import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import {getUserMeta, updateUserMeta} from '../../actions/user-metas';
import Spinner from '../layout/Spinner';
import {getOffices} from '../../actions/offices';
import PropTypes from 'prop-types';

const Profile = ({
  user: {_id},
  getUserMeta,
  updateUserMeta,
  getOffices,
  user_metas: {loading, user_meta},
  offices: {offices},
}) => {
  useEffect(() => {
    if (Object.entries(user_meta).length == 0 && _id) {
      getUserMeta(_id);
      getOffices();
    }
    setFormData({
      employeeId:
        loading || !user_meta.employee_id ? '' : user_meta.employee_id,
      lambdaUrl: loading || !user_meta.lambdaUrl ? '' : user_meta.lambdaUrl,
      lambdaKey: loading || !user_meta.lambdaKey ? '' : user_meta.lambdaKey,
      slackId: loading || !user_meta.slack_id ? '' : user_meta.slack_id,
      wfhQuota: loading || !user_meta.wfhQuota ? '' : user_meta.wfhQuota,
      additionalWfh:
        loading || !user_meta.additionalWfh ? '' : user_meta.additionalWfh,
      WfhDeduction:
        loading || !user_meta.WfhDeduction ? '' : user_meta.WfhDeduction,
      holidayAdjustment:
        loading || !user_meta.holidayAdjustment
          ? ''
          : user_meta.holidayAdjustment,
      office: loading || !user_meta.office ? '' : user_meta.office,
    });
  }, [getUserMeta, user_meta, loading]);
  const [formData, setFormData] = useState({
    employeeId: '',
    lambdaUrl: '',
    lambdaKey: '',
    slackId: '',
    wfhQuota: 6,
    additionalWfh: '',
    WfhDeduction: '',
    holidayAdjustment: '',
    office: '',
  });

  const {
    employeeId,
    lambdaUrl,
    lambdaKey,
    slackId,
    wfhQuota,
    additionalWfh,
    WfhDeduction,
    holidayAdjustment,
    office,
  } = formData;
  const onChange = (e) =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = async (e) => {
    e.preventDefault();
    updateUserMeta(formData, _id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-header'>Profile</div>
        <div className='card-body'>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='employeeId' className='col-sm-2 col-form-label'>
                Employee ID
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='employeeId'
                  className='form-control'
                  id='employeeId'
                  placeholder='employee id'
                  value={employeeId}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='lambdaUrl' className='col-sm-2 col-form-label'>
                AWS Lambda URL
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='lambdaUrl'
                  className='form-control'
                  id='lambdaUrl'
                  placeholder='AWS Lambda URL'
                  value={lambdaUrl}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='lambdaKey' className='col-sm-2 col-form-label'>
                AWS Lambda Key
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='lambdaKey'
                  className='form-control'
                  id='lambdaKey'
                  placeholder='AWS Lambda Key'
                  value={lambdaKey}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='slackId' className='col-sm-2 col-form-label'>
                Slack ID
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='slackId'
                  className='form-control'
                  id='slackId'
                  placeholder='slack id'
                  value={slackId}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <hr />
            <div className='form-group row'>
              <label htmlFor='wfhQuota' className='col-sm-2 col-form-label'>
                WFH Quota
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='wfhQuota'
                  className='form-control'
                  id='wfhQuota'
                  placeholder='WFH Quota'
                  value={wfhQuota}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label
                htmlFor='additionalWfh'
                className='col-sm-2 col-form-label'
              >
                WFH Adjustment
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='additionalWfh'
                  className='form-control'
                  id='additionalWfh'
                  placeholder='WFH Adjustment'
                  value={additionalWfh}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='WfhDeduction' className='col-sm-2 col-form-label'>
                WFH Deduction
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='WfhDeduction'
                  className='form-control'
                  id='WfhDeduction'
                  placeholder='WFH Deduction'
                  value={WfhDeduction}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label
                htmlFor='holidayAdjustment'
                className='col-sm-2 col-form-label'
              >
                Holiday Adjustment
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='holidayAdjustment'
                  className='form-control'
                  id='holidayAdjustment'
                  placeholder='Holiday Adjustment'
                  value={holidayAdjustment}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <hr />
            <div className='form-group row'>
              <label htmlFor='project_desc' className='col-sm-2 col-form-label'>
                Office
              </label>
              <div className='col-sm-10'>
                {' '}
                <select
                  name='office'
                  id='office'
                  className='form-control'
                  value={office}
                  onChange={(e) => onChange(e)}
                >
                  <option key='default' value='-'>
                    select office
                  </option>
                  {offices.map((office) => (
                    <option key={`${office._id}`} value={`${office._id}`}>
                      {office.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

Profile.propTypes = {
  getUserMeta: PropTypes.func.isRequired,
  updateUserMeta: PropTypes.func.isRequired,
  getOffices: PropTypes.func.isRequired,
  user_metas: PropTypes.object.isRequired,
  offices: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user_metas: state.user_metas,
  offices: state.offices,
});

export default connect(mapStateToProps, {
  getUserMeta,
  updateUserMeta,
  getOffices,
})(Profile);
