import React, {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {getOffice, updateOffice} from '../../actions/offices';

const ViewOffice = ({
  getOffice,
  updateOffice,
  offices: {office, loading},
  match,
}) => {
  useEffect(() => {
    if (Object.entries(office).length == 0) {
      getOffice(match.params.id);
    }
    setFormData({
      name: loading || !office.name ? '' : office.name,
      address: loading || !office.address ? '' : office.address,
      phone: loading || !office.phone ? '' : office.phone,
      latitude: loading || !office.latlng ? '' : office.latlng.lat,
      langitude: loading || !office.latlng ? '' : office.latlng.lng,
    });
  }, [getOffice, match.params.id, office, loading]);

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    latitude: '',
    langitude: '',
  });
  const {name, address, phone, latitude, langitude} = formData;

  const onChange = e =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = e => {
    e.preventDefault();
    formData.latlng = {
      lat: formData.latitude,
      lng: formData.langitude,
    };

    updateOffice(formData, match.params.id);
  };

  return loading &&
    Object.entries(office).length === 0 &&
    office.constructor === Object ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card'>
        <div className='card-body'>
          <form onSubmit={e => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='name' className='col-sm-2 col-form-label'>
                Name
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='name'
                  className='form-control'
                  id='name'
                  placeholder='Name'
                  value={name}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='address' className='col-sm-2 col-form-label'>
                Address
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='address'
                  className='form-control'
                  id='address'
                  placeholder='Address'
                  value={address}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='phone' className='col-sm-2 col-form-label'>
                Phone
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='phone'
                  className='form-control'
                  id='phone'
                  placeholder='Phone'
                  value={phone}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='latitude' className='col-sm-2 col-form-label'>
                Latitude
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='latitude'
                  className='form-control'
                  id='latitude'
                  placeholder='Latitude'
                  value={latitude}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='langitude' className='col-sm-2 col-form-label'>
                Langitude
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='langitude'
                  className='form-control'
                  id='langitude'
                  placeholder='Langitude'
                  value={langitude}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  Update
                </button>{' '}
                &nbsp;
                <Link to='/offices-ui' className='btn btn-sm btn-secondary'>
                  Go back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

ViewOffice.propTypes = {
  getOffice: PropTypes.func.isRequired,
  updateOffice: PropTypes.func.isRequired,
  offices: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  offices: state.offices,
});

export default connect(mapStateToProps, {getOffice, updateOffice})(ViewOffice);
