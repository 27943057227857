import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {deleteProjectCategory} from '../../actions/projectCategories';

const ProjectCategoryItem = ({
  auth,
  projectCat: {category_name, _id},
  deleteProjectCategory,
}) => {
  const onClick = e => {
    deleteProjectCategory(e.currentTarget.dataset.id);
  };

  return (
    <Fragment>
      <tr key={`${_id}`}>
        <td>{category_name}</td>
        <td className='text-center'>
          <Link
            to={`/projects/categories/${_id}`}
            className='btn btn-sm btn-success'
          >
            edit
          </Link>{' '}
          &nbsp;
          <button
            onClick={e => onClick(e)}
            data-id={_id}
            className='btn btn-sm btn-danger'
          >
            delete
          </button>
        </td>
      </tr>
    </Fragment>
  );
};

ProjectCategoryItem.propTypes = {
  auth: PropTypes.object.isRequired,
  projectCat: PropTypes.object.isRequired,
  deleteProjectCategory: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {deleteProjectCategory})(
  ProjectCategoryItem
);
