import React, {useState, Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {addTeam} from '../../actions/team';

const AddTeam = ({addTeam}) => {
  const [formData, setFormData] = useState({
    teamName: '',
    teamDesc: '',
  });

  const {teamName, teamDesc} = formData;

  const onChange = e =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = e => {
    e.preventDefault();
    addTeam(formData);
  };

  return (
    <Fragment>
      <div className='card'>
        <div className='card-body'>
          <form onSubmit={e => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='teamName' className='col-sm-2 col-form-label'>
                Name
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='teamName'
                  className='form-control'
                  id='teamName'
                  placeholder='Team Name'
                  value={teamName}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='teamDesc' className='col-sm-2 col-form-label'>
                Team Description
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='teamDesc'
                  className='form-control'
                  id='teamDesc'
                  placeholder='Team Description'
                  value={teamDesc}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  Add
                </button>{' '}
                &nbsp;
                <Link to='/team-ui' className='btn btn-sm btn-secondary'>
                  Go back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

AddTeam.propTypes = {
  addTeam: PropTypes.func.isRequired,
};

export default connect(null, {addTeam})(AddTeam);
