import React, {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {
  getProjectCategory,
  updateProjectCategory,
} from '../../actions/projectCategories';

const ViewProjectCategory = ({
  getProjectCategory,
  updateProjectCategory,
  projectCat: {projectCat, loading},
  match,
}) => {
  useEffect(() => {
    if (!categoryName) {
      getProjectCategory(match.params.id);
      setFormData({
        categoryName:
          loading || !projectCat.category_name ? '' : projectCat.category_name,
        categoryDesc:
          loading || !projectCat.category_desc ? '' : projectCat.category_desc,
      });
    }
  }, [getProjectCategory, match.params.id, projectCat]);

  const [formData, setFormData] = useState({
    categoryName: '',
    categoryDesc: '',
  });
  const {categoryName, categoryDesc} = formData;

  const onChange = e =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = e => {
    e.preventDefault();
    updateProjectCategory(formData, match.params.id);
  };

  return loading &&
    Object.entries(projectCat).length === 0 &&
    projectCat.constructor === Object ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card'>
        <div className='card-body'>
          <form onSubmit={e => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='categoryName' className='col-sm-2 col-form-label'>
                Name
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='categoryName'
                  className='form-control'
                  id='categoryName'
                  placeholder='Category Name'
                  value={categoryName}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='categoryDesc' className='col-sm-2 col-form-label'>
                Description
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='categoryDesc'
                  className='form-control'
                  id='categoryDesc'
                  placeholder='Category Description'
                  value={categoryDesc}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  Update
                </button>{' '}
                &nbsp;
                <Link
                  to='/projects/categories'
                  className='btn btn-sm btn-secondary'
                >
                  Go back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

ViewProjectCategory.propTypes = {
  getProjectCategory: PropTypes.func.isRequired,
  updateProjectCategory: PropTypes.func.isRequired,
  projectCat: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  projectCat: state.projectCategories,
});

export default connect(mapStateToProps, {
  getProjectCategory,
  updateProjectCategory,
})(ViewProjectCategory);
