import {
  GET_USER_METAS,
  GET_USER_META,
  USER_META_ERROR,
  UPDATE_USER_META,
  CLEAR_USER_META,
} from '../actions/types';

const initialState = {
  user_meta: {},
  user_metas: [],
  loading: true,
  error: {},
};

export default function(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_USER_METAS:
      return {
        ...state,
        user_meta: {},
        user_metas: payload,
        loading: false,
      };
    case GET_USER_META:
      return {
        ...state,
        user_meta: payload,
        user_metas: [],
        loading: false,
      };
    case UPDATE_USER_META:
      return {
        ...state,
        user_meta: payload,
        user_metas: [],
        loading: false,
      };
    case USER_META_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_USER_META:
      return {
        ...state,
        user_meta: {},
        user_metas: [],
        loading: false,
      };
    default:
      return state;
  }
}
