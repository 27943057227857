import axios from 'axios';
import {setAlert} from './alert';
import {ADD_TIMELINE, TIMELINE_ERROR, GET_TIMELINE} from './types';
import setHeader from '../utils/SetHeader';

export const addTimeline = (formData, id) => async dispatch => {
  try {
    const req_config = setHeader();
    formData.user = id;
    const res = await axios.post('/timeline/add', formData, req_config);
    dispatch({
      type: ADD_TIMELINE,
      payload: res.data.data,
    });
    dispatch(setAlert('Timeline Added', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: TIMELINE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
export const getUserTimelines = id => async dispatch => {
  try {
    const res = await axios.get(`/timeline/user/${id}`);
    dispatch({
      type: GET_TIMELINE,
      payload: res.data.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: TIMELINE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
