import {
  ADD_TIMELINE,
  TIMELINE_ERROR,
  GET_TIMELINE,
  CLEAR_TIMELINE,
} from '../actions/types';

const initialState = {
  timeline: {},
  timelines: [],
  loading: true,
  error: {},
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ADD_TIMELINE:
      return {
        ...state,
        timeline: {},
        timelines: [payload, ...state.timelines],
        loading: false,
      };
    case TIMELINE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_TIMELINE:
      return {
        ...state,
        timeline: {},
        timelines: [],
        loading: false,
      };
    case GET_TIMELINE:
      return {
        ...state,
        timeline: {},
        timelines: payload,
        loading: false,
      };
    default:
      return state;
  }
}
