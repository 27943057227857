import React, {Fragment, useEffect} from 'react';
import {withRouter, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {getProjectsWithCategory} from '../../actions/projects';
import ProjectItem from './ProjectItem';

const Projects = ({projects: {projects, loading}, getProjectsWithCategory}) => {
  useEffect(() => {
    getProjectsWithCategory();
  }, [getProjectsWithCategory]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <h5 className='card-title'>Projects</h5>
          <p className='card-text'>Lorem ipsum dolor sit amet</p>
          <Link to='/projects/add' className='btn btn-sm btn-primary'>
            Add Item
          </Link>{' '}
          &nbsp;
          <Link to='/projects/categories/' className='btn btn-sm btn-info'>
            Project Categories
          </Link>
        </div>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>Project Name</th>
            <th scope='col' className='text-center'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {projects.map(project => (
            <ProjectItem key={project._id} project={project} />
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

Projects.propTypes = {
  getProjectsWithCategory: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  projects: state.projects,
});

export default connect(mapStateToProps, {getProjectsWithCategory})(
  withRouter(Projects)
);
