import {
  GET_PROJECTS,
  GET_PROJECT,
  PROJECT_ERROR,
  UPDATE_PROJECT,
  ADD_PROJECT,
  DELETE_PROJECT,
  CLEAR_PROJECT,
} from '../actions/types';

const initialState = {
  project: {},
  projects: [],
  loading: true,
  error: {},
};

export default function(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_PROJECTS:
      return {
        ...state,
        project: {},
        projects: payload,
        loading: false,
      };
    case GET_PROJECT:
      return {
        ...state,
        project: payload,
        projects: [],
        loading: false,
      };
    case ADD_PROJECT:
      return {
        ...state,
        project: {},
        projects: [payload, ...state.projects],
        loading: false,
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        project: payload,
        projects: [],
        loading: false,
      };
    case DELETE_PROJECT:
      return {
        ...state,
        project: {},
        projects: state.projects.filter(project => project._id !== payload),
        loading: false,
      };
    case CLEAR_PROJECT:
      return {
        ...state,
        project: {},
        projects: [],
        loading: false,
      };
    case PROJECT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
