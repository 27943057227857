import React, {useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {getTeamWithMemberAndCoordinator, updateTeam} from '../../actions/team';
import TeamListing from './TeamListing';
import AddingTeamMember from './AddingTeamMember';

const ViewTeam = ({
  team: {team, loading},
  getTeamWithMemberAndCoordinator,
  updateTeam,
  match,
}) => {
  useEffect(() => {
    if (!teamName) {
      getTeamWithMemberAndCoordinator(match.params.id);
      setFormData({
        teamName: loading || !team.team_name ? '' : team.team_name,
        teamDesc: loading || !team.team_desc ? '' : team.team_desc,
      });
    }
  }, [getTeamWithMemberAndCoordinator, team, loading]);
  const [formData, setFormData] = useState({
    teamName: '',
    teamDesc: '',
  });

  const {teamName, teamDesc} = formData;

  const onChange = e =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = e => {
    e.preventDefault();
    console.log(formData);
    updateTeam(formData, match.params.id);
  };
  return loading ||
    (Object.entries(team).length === 0 && team.constructor === Object) ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <form onSubmit={e => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='teamName' className='col-sm-2 col-form-label'>
                Name
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='teamName'
                  className='form-control'
                  id='teamName'
                  placeholder='Team Name'
                  value={teamName}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='teamDesc' className='col-sm-2 col-form-label'>
                Team Description
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='teamDesc'
                  className='form-control'
                  id='teamDesc'
                  placeholder='Team Description'
                  value={teamDesc}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  Update
                </button>{' '}
                &nbsp;
                <Link to='/team-ui' className='btn btn-sm btn-secondary'>
                  Go back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <TeamListing key='team-listing' />
      <AddingTeamMember key='add-team-member' team={team} />
    </Fragment>
  );
};

ViewTeam.propTypes = {
  updateTeam: PropTypes.func.isRequired,
  getTeamWithMemberAndCoordinator: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  team: state.team,
});

export default connect(mapStateToProps, {
  updateTeam,
  getTeamWithMemberAndCoordinator,
})(ViewTeam);
