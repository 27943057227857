export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const GENERATE_LINK_SUCCESS = 'GENERATE_LINK_SUCCESS';
export const GENERATE_LINK_FAIL = 'GENERATE_LINK_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const CLEAR_PROJECT = 'CLEAR_PROJECT';

export const GET_PROJECTS_CAT = 'GET_PROJECTS_CAT';
export const GET_PROJECT_CAT = 'GET_PROJECT_CAT';
export const ADD_PROJECT_CAT = 'ADD_PROJECT_CAT';
export const UPDATE_PROJECT_CAT = 'UPDATE_PROJECT_CAT';
export const DELETE_PROJECT_CAT = 'DELETE_PROJECT_CAT';
export const PROJECT_CAT_ERROR = 'PROJECT_CAT_ERROR';
export const CLEAR_PROJECT_CAT = 'CLEAR_PROJECT_CAT';

export const GET_TEAMS = 'GET_TEAMS';
export const GET_TEAM = 'GET_TEAM';
export const ADD_TEAM = 'ADD_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const TEAM_ERROR = 'TEAM_ERROR';
export const CLEAR_TEAM = 'CLEAR_TEAM';

export const GET_OFFICES = 'GET_OFFICES';
export const GET_OFFICE = 'GET_OFFICE';
export const ADD_OFFICE = 'ADD_OFFICE';
export const UPDATE_OFFICE = 'UPDATE_OFFICE';
export const DELETE_OFFICE = 'DELETE_OFFICE';
export const OFFICE_ERROR = 'OFFICE_ERROR';
export const CLEAR_OFFICE = 'CLEAR_OFFICE';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_ERROR = 'PROFILE_ERROR';

export const ADD_TIMELINE = 'ADD_TIMELINE';
export const GET_TIMELINE = 'GET_TIMELINE';
export const CLEAR_TIMELINE = 'CLEAR_TIMELINE';
export const TIMELINE_ERROR = 'TIMELINE_ERROR';

export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_TEAM';
export const DELETE_USER = 'DELETE_USER';
export const USER_ERROR = 'USER_ERROR';
export const CLEAR_USER = 'CLEAR_USER';

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const GET_ADDRESS = 'GET_ADDRESS';
export const GET_ADDRESSES = 'GET_ADDRESSES';
export const CLEAR_ADDRESS = 'CLEAR_ADDRESS';
export const ADDRESS_ERROR = 'ADDRESS_ERROR';

export const GET_USER_METAS = 'GET_USER_METAS';
export const GET_USER_META = 'GET_USER_META';
export const ADD_USER_META = 'ADD_USER_META';
export const UPDATE_USER_META = 'UPDATE_USER_META';
export const DELETE_USER_META = 'DELETE_USER_META';
export const USER_META_ERROR = 'USER_META_ERROR';
export const CLEAR_USER_META = 'CLEAR_USER_META';

export const DISC_TOKEN = 'DISC_TOKEN';
