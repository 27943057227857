import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {deleteOffice} from '../../actions/offices';

const OfficeItem = ({auth, office: {_id, name, address}, deleteOffice}) => {
  const onClick = e => {
    deleteOffice(e.currentTarget.dataset.id);
  };

  return (
    <Fragment>
      <tr key={`${_id}`}>
        <td>
          {name}
          <br />
          <span className='small text-muted'>{address}</span>
        </td>
        <td className='text-center align-middle'>
          <Link to={`/offices-ui/${_id}`} className='btn btn-sm btn-success'>
            edit
          </Link>{' '}
          &nbsp;
          <button
            onClick={e => onClick(e)}
            data-id={_id}
            className='btn btn-sm btn-danger'
          >
            delete
          </button>
        </td>
      </tr>
    </Fragment>
  );
};

OfficeItem.propTypes = {
  auth: PropTypes.object.isRequired,
  office: PropTypes.object.isRequired,
  deleteOffice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {deleteOffice})(OfficeItem);
