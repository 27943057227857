import axios from 'axios';
import {
  GET_OFFICES,
  GET_OFFICE,
  OFFICE_ERROR,
  ADD_OFFICE,
  UPDATE_OFFICE,
  DELETE_OFFICE,
} from './types';
import {setAlert} from './alert';
import setHeader from '../utils/SetHeader';

// get Office
export const getOffices = () => async dispatch => {
  try {
    const res = await axios.get('/offices');
    dispatch({type: GET_OFFICES, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: OFFICE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getOffice = id => async dispatch => {
  try {
    const res = await axios.get(`/offices/${id}`);
    dispatch({type: GET_OFFICE, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: OFFICE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const updateOffice = (formData, id) => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post(`/offices/update/${id}`, formData, reqConfig);
    dispatch({
      type: UPDATE_OFFICE,
      payload: res.data.data,
    });
    dispatch(setAlert('Office Updated', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: OFFICE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const deleteOffice = id => async dispatch => {
  try {
    const reqConfig = setHeader();
    await axios.delete(`/offices/${id}`, null, reqConfig);
    dispatch({
      type: DELETE_OFFICE,
      payload: id,
    });
    dispatch(setAlert('Office Deleted', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: OFFICE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const addOffice = formData => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post('/offices/add', formData, reqConfig);
    dispatch({
      type: ADD_OFFICE,
      payload: res.data.data,
    });
    dispatch(setAlert('Office Added', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: OFFICE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
