import {
  GET_ADDRESSES,
  GET_ADDRESS,
  ADDRESS_ERROR,
  UPDATE_ADDRESS,
  CLEAR_ADDRESS,
} from '../actions/types';

const initialState = {
  user_address: {},
  user_addresses: [],
  loading: true,
  error: {},
};

export default function(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_ADDRESSES:
      return {
        ...state,
        user_address: {},
        user_addresses: payload,
        loading: false,
      };
    case GET_ADDRESS:
      return {
        ...state,
        user_address: payload,
        user_addresses: [],
        loading: false,
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        user_address: payload,
        user_addresses: [],
        loading: false,
      };
    case ADDRESS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_ADDRESS:
      return {
        ...state,
        user_address: {},
        user_addresses: [],
        loading: false,
      };
    default:
      return state;
  }
}
