import {combineReducers} from 'redux';
import alert from './alert';
import auth from './auth';
import disc from './disc';
import projects from './projects';
import team from './team';
import profiles from './profile';
import projectCategories from './projectCategories';
import timeline from './timeline';
import users from './users';
import user_metas from './user-metas';
import user_address from './user-address';
import offices from './offices';

export default combineReducers({
  alert,
  auth,
  disc,
  projects,
  projectCategories,
  profiles,
  team,
  timeline,
  users,
  user_metas,
  user_address,
  offices,
});
