import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {deleteUser} from '../../actions/users';

const UserItem = ({user, deleteUser}) => {
  const onClick = (e) => {
    deleteUser(e.currentTarget.dataset.id);
  };

  const Namestyle = ({user: {_id, name, email, date_deleted}}) => {
    let nameStyled = (
      <td>
        {name} - <span className='small text-muted'>{email}</span>
      </td>
    );
    if (date_deleted) {
      nameStyled = (
        <td className='text-danger'>
          {name} - <span className='small'>{email}</span>
        </td>
      );
    }
    return nameStyled;
  };
  return (
    <Fragment>
      <tr key={user._id}>
        <Namestyle key={user._id} user={user} />
        <td className='text-center'>
          <Link to={`/users-ui/${user._id}`} className='btn btn-sm btn-success'>
            edit
          </Link>{' '}
          &nbsp;
          <button
            onClick={(e) => onClick(e)}
            data-id={user._id}
            className='btn btn-sm btn-danger'
          >
            delete
          </button>
        </td>
      </tr>
    </Fragment>
  );
};

UserItem.propTypes = {
  auth: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {deleteUser})(UserItem);
