import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Can from '../routing/Can';
import config from '../../config';
import Spinner from '../layout/Spinner';
import MapContainer from '../alamat/Map';

import { updateProfile, getCurrentProfile } from '../../actions/profile';

const crypto = require('crypto');

const Profile = ({
  auth: { user, loading },
  updateProfile,
  getCurrentProfile,
  history,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    // mobile_number: "",
    password: '',
  });
  const { name, email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const slug = crypto.createHash('md5').update(email).digest('hex');

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    let password_hash = await crypto
      .createHash('md5')
      .update(formData.password)
      .digest('hex');
    formData.password = password_hash;
    updateProfile(user._id, formData, history);
    // formData.password = 'krafthaus!';
  };
  useEffect(() => {
    getCurrentProfile();
    setFormData({
      name: loading || !user.name ? '' : user.name,
      email: loading || !user.email ? '' : user.email,
      password: 'krafthaus!',
    });
  }, [loading, getCurrentProfile]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <h5 className='card-title'>Profile</h5>
          <p className='card-text'>
            <b>Update</b> your <i>Profile</i> data below.
            <br />
            <b>Add</b> the <i>direct link</i> to your bookmark to get quicker
            access.
          </p>
        </div>
      </div>
      <div className='card-deck'>
        <div className='card mb-4'>
          <div className='card-body'>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className='form-group'>
                <label htmlFor='inputName' className='col-sm-12 col-form-label'>
                  Name
                </label>
                <div className='col-sm-12'>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    id='inputName'
                    placeholder='Name'
                    value={name}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <div className='form-group '>
                <label
                  htmlFor='inputEmail'
                  className='col-sm-12 col-form-label'
                >
                  Email
                </label>
                <div className='col-sm-12'>
                  <input
                    type='email'
                    name='email'
                    className='form-control'
                    id='inputEmail'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <div className='form-group '>
                <label
                  htmlFor='inputPassword'
                  className='col-sm-12 col-form-label'
                >
                  Password
                </label>
                <div className='col-sm-12'>
                  <input
                    type='password'
                    name='password'
                    className='form-control'
                    id='inputPassword'
                    placeholder='****'
                    value={password}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='inputSlug' className='col-sm-12 col-form-label'>
                  Direct Link
                </label>
                <div className='col-sm-12'>
                  <pre>{config.AUTH_URL + 'auth/' + slug}</pre>
                </div>
              </div>
              <Can
                role={user.role}
                perform='profile:update'
                yes={() => (
                  <div className='form-group '>
                    <div className='col-sm-12'>
                      <button type='submit' className='btn btn-sm btn-success'>
                        Update
                      </button>
                    </div>
                  </div>
                )}
                no={() => ''}
              />
            </form>
          </div>
        </div>
        <div className='card mb-4'>
          <div className='card-body'>
            <p id='help' className='form-text text-muted'>
              Perbarui alamat dengan cara:
            </p>
            <br />
            <ul>
              <li>
                Ketikan alamat pada field <i>Address</i>.
              </li>
              <li>
                Tekan tombol <i>find your address</i>, maka akan muncul daftar
                alamat yang mendekati alamat.
              </li>
              <li>
                Pilih salah satu alamat yang mendekati, kemudian peta akan
                menunjukan daerah sesuai alamat yang dipilih.
              </li>
              <li>Klik/tekan titik lokasi pada peta yang menunjukkan rumah.</li>
            </ul>
            Atau, bisa langsung:
            <ul>
              <li>Klik/tekan titik lokasi pada peta yang menunjukkan rumah.</li>
            </ul>
          </div>
        </div>
      </div>
      <MapContainer type='editor' />
    </Fragment>
  );
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateProfile, getCurrentProfile })(
  withRouter(Profile)
);
