import React, {useState, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {getUser} from '../../actions/users-pub';
import MapContainer from './Map';

const UserEdit = ({getUser, users: {user, loading}, match}) => {
  useEffect(() => {
    if (!name) {
      getUser(match.params.id);
    }
    setFormData({
      name: loading || !user.name ? '' : user.name,
      email: loading || !user.email ? '' : user.email,
    });
  }, [match.params.id, user, getUser, loading]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const {name, email} = formData;

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card-deck'>
        <div className='card mb-4'>
          <div className='card-body'>
            <form>
              <div className='form-group row'>
                <label htmlFor='name' className='col-sm-2 col-form-label'>
                  Name
                </label>
                <div className='col-sm-10'>
                  <label className='col-form-label'>{name}</label>
                </div>
              </div>
              <div className='form-group row'>
                <label htmlFor='email' className='col-sm-2 col-form-label'>
                  Email
                </label>
                <div className='col-sm-10'>
                  <label className='col-form-label'>{email}</label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='card mb-4'>
          <div className='card-body'>
            <p id='help' className='form-text text-muted'>
              Akses halaman profil masing masing untuk memperbarui alamat.
            </p>
          </div>
        </div>
      </div>
      <MapContainer type='viewer' />
    </Fragment>
  );
};

UserEdit.propTypes = {
  users: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  users: state.users,
});

export default connect(mapStateToProps, {getUser})(UserEdit);
