import axios from 'axios';
import {GET_USER_META, UPDATE_USER_META, USER_META_ERROR} from './types';
import setHeader from '../utils/SetHeader';
import {setAlert} from './alert';

export const getUserMeta = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/user-metas/${id}`);
    dispatch({type: GET_USER_META, payload: res.data.data});
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      // errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: USER_META_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const updateUserMeta = (formData, id) => async (dispatch) => {
  const req_config = setHeader();
  try {
    const res = await axios.post(
      `/user-metas/update/${id}`,
      formData,
      req_config
    );
    dispatch({
      type: UPDATE_USER_META,
      payload: res.data.data,
    });
    dispatch(setAlert('Sudah terupdate', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: USER_META_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
