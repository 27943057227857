const dev = {
  URL: 'http://localhost:3001/',
  BACKEND_URL: 'http://localhost:4000/',
  TAP_URL: 'http://localhost:3001/peoplehr',
  MANAGER_URL: 'http://localhost:3001/peoplem/',
  AUTH_URL: 'http://localhost:3002/',
  XERO_URL: 'http://localhost:5051',
  ZENTALK_URL: 'http://localhost:6062',
  PPP_URL: 'http://localhost:3031/dashboard',
  EXT_URL: {
    LINK: 'http://localhost:2015/',
    LINK_AWS: 'http://localhost:2015/',
  },
  KUCING_URL: 'http://localhost:3007/',
  DISC_TEST_URL: 'http://localhost:5103/',
  JOIN_URL: 'http://localhost:5103/',
  COVER_URL: 'http://localhost:4103/',
  REVIEW_URL: 'http://localhost:7103',
  PAGESPEED_URL: 'http://localhost:8103/',
  TITLE: 'Krafthaus - Dev',
};
const prod = {
  URL: 'https://panel.kraftha.us/',
  BACKEND_URL: 'http://localhost:4000/',
  TAP_URL: 'https://slack.kraftha.us/peoplehr/',
  MANAGER_URL: 'https://slack.kraftha.us/peoplem/',
  AUTH_URL: 'https://auth.kraftha.us/',
  XERO_URL: 'https://xero.kraftha.us/dashboard/',
  ZENTALK_URL: 'https://zentalk.kraftha.us/dashboard/',
  PPP_URL: 'https://ppp.kraftha.us/dashboard/',
  LINK_URL: '',
  EXT_URL: {
    LINK: 'https://url.kraftha.us/',
    LINK_AWS: 'https://url-v2.kraftha.us/',
  },
  KUCING_URL: 'https://kucing.kraftha.us/',
  DISC_TEST_URL: 'https://disc.kraftha.us/',
  JOIN_URL: 'https://join.kraftha.us/',
  COVER_URL: 'https://cover.kraftha.us/',
  REVIEW_URL: 'https://review.kraftha.us',
  PAGESPEED_URL: 'https://pagespeed.kraftha.us/',
  TITLE: 'Krafthaus',
};

const config = process.env.REACT_APP_STAGE === 'dev' ? dev : prod;

export default config;
