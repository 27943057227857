import {
  ADD_USER,
  UPDATE_USER,
  GET_USER,
  GET_USERS,
  USER_ERROR,
  DELETE_USER,
} from '../actions/types';

const initialState = {
  user: {},
  users: [],
  loading: true,
  error: {},
};

export default function(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_USERS:
      return {
        ...state,
        user: {},
        users: payload,
        loading: false,
      };
    case ADD_USER:
      return {
        ...state,
        user: {},
        users: [payload, ...state.users],
        loading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
        users: [],
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        user: {},
        users: state.users.filter(user => user._id !== payload),
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        user: payload,
        users: [],
        loading: false,
      };
    case USER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
