import React, {useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getUser, updateUser} from '../../actions/users';
import ProfileContainer from './Profile';
import ProfileAddress from './ProfileAddress';
import Spinner from '../layout/Spinner';
const crypto = require('crypto');

const ViewUser = ({getUser, updateUser, match, users: {user, loading}}) => {
  useEffect(() => {
    if (!name) {
      getUser(match.params.id);
      setFormData({
        ...formData,
        name: loading || !user.name ? '' : user.name,
        email: loading || !user.email ? '' : user.email,
        role: loading || !user.role ? '' : user.role,
      });
    }
  }, [getUser, match.params.id, user]);
  const [formData, setFormData] = useState({
    name: '',
    password: 'krafthaus!',
    email: '',
    role: '',
  });

  const {name, email, password, role} = formData;

  const onChange = (e) =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = async (e) => {
    e.preventDefault();
    let passwordHash = await crypto
      .createHash('md5')
      .update(formData.password)
      .digest('hex');
    formData.password = passwordHash;
    updateUser(formData, match.params.id);
    // formData.password = 'krafthaus!';
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='name' className='col-sm-2 col-form-label'>
                Name
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='name'
                  className='form-control'
                  id='name'
                  placeholder='Name'
                  value={name}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='email' className='col-sm-2 col-form-label'>
                Email
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='email'
                  className='form-control'
                  id='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='password' className='col-sm-2 col-form-label'>
                Password
              </label>
              <div className='col-sm-10'>
                <input
                  type='password'
                  name='password'
                  className='form-control'
                  id='password'
                  placeholder='password'
                  value={password}
                  onChange={(e) => onChange(e)}
                />
                <small id='password' className='form-text text-muted'>
                  Default password is{' '}
                  <span className='bg-secondary text-white'> krafthaus! </span>
                </small>
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='role' className='col-sm-2 col-form-label'>
                Role
              </label>
              <div className='col-sm-10'>
                <select
                  name='role'
                  id='role'
                  className='form-control'
                  value={role}
                  onChange={(e) => onChange(e)}
                >
                  <option value='admin'>admin</option>
                  <option value='cover'>cover</option>
                  <option value='dev'>dev</option>
                  <option value='user'>user</option>
                </select>
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  Update
                </button>{' '}
                &nbsp;
                <Link to='/users-ui/' className='btn btn-sm btn-secondary'>
                  Go back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ProfileContainer key={'container-' + user._id} user={user} />
      <ProfileAddress key={'address-' + user._id} user={user}></ProfileAddress>
    </Fragment>
  );
};

ViewUser.propTypes = {
  updateUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, {updateUser, getUser})(ViewUser);
