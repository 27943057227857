import axios from 'axios';
import {
  GET_TEAMS,
  GET_TEAM,
  ADD_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  TEAM_ERROR,
} from './types';
import {setAlert} from './alert';
import setHeader from '../utils/SetHeader';

export const getTeams = () => async dispatch => {
  try {
    const res = await axios.get('/team');
    dispatch({type: GET_TEAMS, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: TEAM_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getTeam = id => async dispatch => {
  try {
    const res = await axios.get(`/team/${id}`);
    dispatch({type: GET_TEAM, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: TEAM_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getTeamWithMemberAndCoordinator = id => async dispatch => {
  try {
    const res = await axios.get(`/team/${id}/allmember`);
    dispatch({type: GET_TEAM, payload: res.data.data});
  } catch (error) {
    console.log(error);
    dispatch({
      type: TEAM_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const updateTeam = (formData, id) => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post(`/team/update/${id}`, formData, reqConfig);

    dispatch({
      type: UPDATE_TEAM,
      payload: res.data.data,
    });
    dispatch(setAlert('Team Updated', 'success'));
  } catch (error) {
    console.log(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: TEAM_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const deleteTeam = id => async dispatch => {
  try {
    const reqConfig = setHeader();
    await axios.delete(`/team/${id}`, null, reqConfig);
    dispatch({
      type: DELETE_TEAM,
      payload: id,
    });
    dispatch(setAlert('Team Deleted', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: TEAM_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const addTeam = formData => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post('/team/add', formData, reqConfig);
    dispatch({
      type: ADD_TEAM,
      payload: res.data.data,
    });
    dispatch(setAlert('Team Added', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: TEAM_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
