import React, {useState, Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setAlert} from '../../actions/alert';
import {addUser} from '../../actions/users';
const crypto = require('crypto');

const AddUser = ({addUser, setAlert}) => {
  const [formData, setFormData] = useState({
    name: '',
    password: 'krafthaus!',
    email: '',
  });

  const {name, password, email} = formData;

  const onChange = (e) =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      email.includes('@krafthaus') ||
      email.includes('@paperlust') ||
      email.includes('@obdservices')
    ) {
      let slug = await crypto.createHash('md5').update(email).digest('hex');
      let passwordHash = await crypto
        .createHash('md5')
        .update(password)
        .digest('hex');
      addUser({name, email, slug, passwordHash});
      //   addUser(formData);
    } else {
      setAlert(
        'Please use @krafthaus / @paperlust / @obdservices email.',
        'error'
      );
    }
  };

  return (
    <Fragment>
      <div className='card'>
        <div className='card-body'>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='name' className='col-sm-2 col-form-label'>
                Name
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='name'
                  className='form-control'
                  id='name'
                  placeholder='Name'
                  value={name}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='email' className='col-sm-2 col-form-label'>
                Email
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='email'
                  className='form-control'
                  id='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => onChange(e)}
                />
                <small id='email' className='form-text text-muted'>
                  Default password is{' '}
                  <span className='bg-secondary text-white'> Krafthaus! </span>
                </small>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  Add
                </button>{' '}
                &nbsp;
                <Link to='/users-ui/' className='btn btn-sm btn-secondary'>
                  Go back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

AddUser.propTypes = {
  addUser: PropTypes.func.isRequired,
};

export default connect(null, {addUser, setAlert})(AddUser);
