import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {deleteTeam} from '../../actions/team';

const TeamItem = ({auth, team: {_id, team_name}, deleteTeam}) => {
  const onClick = e => {
    deleteTeam(e.currentTarget.dataset.id);
  };
  return (
    <Fragment>
      <tr key={_id}>
        <td>{team_name}</td>
        <td className='text-center'>
          <Link to={`/team-ui/${_id}`} className='btn btn-sm btn-success'>
            edit
          </Link>{' '}
          &nbsp;
          <button
            onClick={e => onClick(e)}
            data-id={_id}
            className='btn btn-sm btn-danger'
          >
            delete
          </button>
        </td>
      </tr>
    </Fragment>
  );
};

TeamItem.propTypes = {
  auth: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  deleteTeam: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {deleteTeam})(TeamItem);
