import React, {useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {addProject} from '../../actions/projects';
import {getProjectCategories} from '../../actions/projectCategories';

const AddProject = ({
  addProject,
  getProjectCategories,
  projectCategories: {projectCats, loading},
}) => {
  useEffect(() => {
    if (!category) {
      getProjectCategories();
      setFormData({
        projectName: '',
        projectKey: '',
        projectDesc: '',
        category: loading || !projectCats ? '' : projectCats[0]._id,
      });
    }
  }, [getProjectCategories, projectCats, loading]);

  const [formData, setFormData] = useState({
    projectName: '',
    projectKey: '',
    projectDesc: '',
    category: '',
  });

  const {projectName, projectKey, projectDesc, category} = formData;

  const onChange = e =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = e => {
    e.preventDefault();
    addProject(formData);
  };

  return loading && projectCats ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card'>
        <div className='card-body'>
          <form onSubmit={e => onSubmit(e)}>
            <div className='form-group row'>
              <label htmlFor='projectName' className='col-sm-2 col-form-label'>
                Name
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='projectName'
                  className='form-control'
                  id='projectName'
                  placeholder='Project Name'
                  value={projectName}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='projectKey' className='col-sm-2 col-form-label'>
                Name
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='projectKey'
                  className='form-control'
                  id='projectKey'
                  placeholder='Project Key'
                  value={projectKey}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='projectDesc' className='col-sm-2 col-form-label'>
                Project Description
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  name='projectDesc'
                  className='form-control'
                  id='projectDesc'
                  placeholder='Project Description'
                  value={projectDesc}
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='category' className='col-sm-2 col-form-label'>
                Project Category
              </label>
              <div className='col-sm-10'>
                <select
                  name='category'
                  id='category'
                  className='form-control'
                  value={category}
                  onChange={e => onChange(e)}
                >
                  {projectCats.map(category => (
                    <option key={`${category._id}`} value={`${category._id}`}>
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-sm-10'>
                <button type='submit' className='btn btn-sm btn-success'>
                  Add
                </button>{' '}
                &nbsp;
                <Link to='/projects' className='btn btn-sm btn-secondary'>
                  Go back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

AddProject.propTypes = {
  addProject: PropTypes.func.isRequired,
  getProjectCategories: PropTypes.func.isRequired,
  projectCategories: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  projectCategories: state.projectCategories,
});

export default connect(mapStateToProps, {addProject, getProjectCategories})(
  AddProject
);
