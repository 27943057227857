import axios from 'axios';
import {GET_PROFILE, PROFILE_ERROR, GET_PROFILES, GET_USER} from './types';
import {setAlert} from '../actions/alert';
import setHeader from '../utils/SetHeader';

// get project
export const getCurrentProfile = () => async dispatch => {
  try {
    const res = await axios.get('/users/me');
    dispatch({type: GET_PROFILE, payload: res.data.data});
    dispatch({type: GET_USER, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

// create or update profile
export const updateProfile = (id, formData, history) => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post('/users/update/' + id, formData, reqConfig);
    dispatch({type: GET_PROFILE, payload: res.data.data});
    dispatch(setAlert('Profile updated.', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROFILE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getAllProfiles = () => async dispatch => {
  try {
    const res = await axios.get('/users');
    dispatch({type: GET_PROFILES, payload: res.data.data});
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROFILE_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
