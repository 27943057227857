import {
  GET_PROJECTS_CAT,
  GET_PROJECT_CAT,
  ADD_PROJECT_CAT,
  PROJECT_CAT_ERROR,
  CLEAR_PROJECT_CAT,
  DELETE_PROJECT_CAT,
  UPDATE_PROJECT_CAT,
} from '../actions/types';

const initialState = {
  projectCat: {},
  projectCats: [],
  loading: true,
  error: {},
};

export default function(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_PROJECTS_CAT:
      return {
        ...state,
        projectCat: {},
        projectCats: payload,
        loading: false,
      };
    case GET_PROJECT_CAT:
      return {
        ...state,
        projectCat: payload,
        projectCats: [],
        loading: false,
      };
    case DELETE_PROJECT_CAT:
      return {
        ...state,
        projectCat: {},
        projectCats: state.projectCats.filter(cat => cat._id !== payload),
        loading: false,
      };
    case UPDATE_PROJECT_CAT:
      return {
        ...state,
        projectCat: payload,
        projectCats: [],
        loading: false,
      };
    case CLEAR_PROJECT_CAT:
      return {
        ...state,
        projectCat: {},
        projectCats: [],
        loading: false,
      };
    case ADD_PROJECT_CAT:
      return {
        ...state,
        projectCats: [payload, ...state.projectCats],
        loading: false,
      };
    case PROJECT_CAT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
