import React, {Fragment, useEffect} from 'react';
import {withRouter, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {getProjectCategories} from '../../actions/projectCategories';
import ProjectCategoryItem from './ProjectCategoryItem';

const ProjectCategories = ({
  projectCats: {projectCats, loading},
  getProjectCategories,
}) => {
  useEffect(() => {
    getProjectCategories();
  }, [getProjectCategories]);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <h5 className='card-title'>ProjectCategories</h5>
          <p className='card-text'>Lorem ipsum dolor sit amet</p>
          <Link
            to='/projects/categories/add'
            className='btn btn-sm btn-primary'
          >
            Add Item
          </Link>{' '}
          &nbsp;
          <Link to='/projects/' className='btn btn-sm btn-secondary'>
            Back
          </Link>
        </div>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>Project Name</th>
            <th scope='col' className='text-center'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {projectCats.map(category => (
            <ProjectCategoryItem key={category._id} projectCat={category} />
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

ProjectCategories.propTypes = {
  getProjectCategories: PropTypes.func.isRequired,
  projectCats: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  projectCats: state.projectCategories,
});

export default connect(mapStateToProps, {getProjectCategories})(
  withRouter(ProjectCategories)
);
