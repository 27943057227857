import axios from 'axios';
import {
  GET_USERS,
  GET_USER,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  USER_ERROR,
  CLEAR_USER_META,
  CLEAR_ADDRESS,
  DISC_TOKEN,
} from './types';
import {setAlert} from './alert';
import setHeader from '../utils/SetHeader';
var bcrypt = require('bcryptjs');

export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get('/users');
    dispatch({type: GET_USERS, payload: res.data.data});
    dispatch({type: CLEAR_USER_META});
    dispatch({type: CLEAR_ADDRESS});
  } catch (error) {
    dispatch({
      type: USER_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/users/${id}`);
    dispatch({type: GET_USER, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: USER_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const updateUser = (formData, id) => async (dispatch) => {
  if (
    formData.email.includes('@krafthaus') ||
    formData.email.includes('@paperlust') ||
    formData.email.includes('@obdservices')
  ) {
    try {
      const req_config = setHeader();
      const res = await axios.post(`/users/update/${id}`, formData, req_config);
      dispatch({
        type: UPDATE_USER,
        payload: res.data.data[0].data,
      });
      dispatch(setAlert(res.data.data[0].msg, 'success'));
      // dispatch(setAlert('User Updated', 'success'));
    } catch (error) {
      console.log(error);
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: USER_ERROR,
        payload: {msg: error.response, status: error.response.status},
      });
    }
  } else {
    dispatch(
      setAlert(
        'Please use @krafthaus / @paperlust / @obdservices email.',
        'error'
      )
    );
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const req_config = setHeader();
    await axios.delete(`/user-metas/${id}`, null, req_config);
    await axios.delete(`/user-address/${id}`, null, req_config);
    await axios.delete(`/users/${id}`, null, req_config);
    dispatch({
      type: DELETE_USER,
      payload: id,
    });
    const res = await axios.get('/users');
    dispatch({type: GET_USERS, payload: res.data.data});
    dispatch({type: CLEAR_USER_META});
    dispatch({type: CLEAR_ADDRESS});
    dispatch(setAlert('User Deleted', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: USER_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const addUser = (formData) => async (dispatch) => {
  try {
    const req_config = setHeader();
    const res = await axios.post('/users/add', formData, req_config);
    console.log(res);
    dispatch({
      type: ADD_USER,
      payload: res.data.data,
    });
    dispatch(setAlert(res.data.data[0].msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg), 'error'));
    }
    dispatch({
      type: USER_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const buildDownloadFile = () => async (dispatch) => {
  try {
    const req_config = setHeader();
    const res = await axios.get('/cron/distance', null, req_config);
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};

export const discToken = () => async (dispatch) => {
  const salt = await bcrypt.genSalt(10);
  const result = await bcrypt.hash('jangansoktau', salt);
  dispatch({
    type: DISC_TOKEN,
    payload: result,
  });
};
