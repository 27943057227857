import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import {getUsers} from '../../actions/users-pub';
import UserItem from './UserItem';

const Dashboard = ({users: {users, loading}, getUsers}) => {
  useEffect(() => {
    getUsers();
  }, [getUsers]);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card border mb-4'>
        <div className='card-body'>
          <h5 className='card-title'>
            Halo, ini sistem untuk mendata jarak rumah ke kantor
          </h5>
          <p className='card-text text-muted'>
            Silakan perbarui alamat teman-teman dengan cara pilih <i>update</i>.
            <br />
            Alamat terbaru akan digunakan sebagai pertimbangan tunjangan
            transportasi pada payslip.
            <br />
            Alamat dapat diperbarui pada halaman profil masing-masing.
          </p>
        </div>
      </div>
      <div className='card-columns'>
        {users.map((user) => (
          <UserItem key={user._id} user={user} />
        ))}
      </div>
    </Fragment>
  );
};

Dashboard.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, {getUsers})(Dashboard);
