import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {updateTeam} from '../../actions/team';

const TeamListing = ({team: {team, loading}, updateTeam}) => {
  const onClick = e => {
    if (e.currentTarget.dataset.type == 'team-coordinator') {
      team.team_coordinator = team.team_coordinator.filter(
        coordinator => coordinator._id !== e.currentTarget.dataset.id
      );
    } else {
      team.team_members = team.team_members.filter(
        members => members._id !== e.currentTarget.dataset.id
      );
    }
    team.teamCoordinator = team.team_coordinator;
    team.teamMembers = team.team_members;
    team.teamName = team.team_name;
    team.teamDesc = team.team_desc;
    updateTeam(team, team._id);
  };
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <h6 className='text-muted card-subtitle'>Team Coordinator</h6>
          <table className='table' key='team-coordinator-table'>
            <tbody>
              {team.team_coordinator.map(team_coordinator => (
                <tr key={`${team_coordinator._id}`}>
                  <td>
                    {team_coordinator.name} -{' '}
                    <span className='small text-muted'>
                      {team_coordinator.email}
                    </span>
                  </td>
                  <td>
                    <button
                      onClick={e => onClick(e)}
                      data-id={team_coordinator._id}
                      data-type='team-coordinator'
                      className='btn btn-sm btn-danger'
                    >
                      remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='card mb-4'>
        <div className='card-body'>
          <h6 className='text-muted card-subtitle'>Team Member</h6>
          <table className='table' key='team-member-table'>
            <tbody>
              {team.team_members.map(team_member => (
                <tr key={`${team_member._id}`}>
                  <td>
                    {team_member.name} -{' '}
                    <span className='small text-muted'>
                      {team_member.email}
                    </span>
                  </td>
                  <td>
                    <button
                      onClick={e => onClick(e)}
                      data-id={team_member._id}
                      data-type='team-member'
                      className='btn btn-sm btn-danger'
                    >
                      remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

TeamListing.propTypes = {
  team: PropTypes.object.isRequired,
  updateTeam: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  team: state.team,
});

export default connect(mapStateToProps, {updateTeam})(TeamListing);
