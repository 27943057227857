// import React from "react";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";

// const Alert = ({ alerts }) =>
//   alerts !== null &&
//   alerts.length > 0 &&
//   alerts.map(alert => (
//     <div
//       role="alert"
//       key={alert.id}
//       className={`alert alert-${alert.alertType}`}
//     >
//       {alert.msg}
//     </div>
//   ));

// Alert.propTypes = {
//   alerts: PropTypes.array.isRequired
// };

// const mapStateToProps = state => ({
//   alerts: state.alert
// });

// export default connect(mapStateToProps)(Alert);

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import SweetAlert from 'sweetalert2-react';
import config from '../../config';

const Alert = ({alerts}) => {
  return alerts !== null && alerts.length > 0 ? (
    alerts.map(alert => (
      <SweetAlert
        type={`${alert.alertType}`}
        show='true'
        title={`${config.TITLE}`}
        text={`${alert.msg}`}
        // showConfirmButton={false}
        onConfirm={() => console.log('test')}
        key={alert.id}
      />
    ))
  ) : (
    <Fragment />
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
