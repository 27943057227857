import axios from 'axios';
import {
  GET_PROJECTS,
  GET_PROJECT,
  PROJECT_ERROR,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
} from './types';
import {setAlert} from './alert';
import setHeader from '../utils/SetHeader';

// get project
export const getProjects = () => async dispatch => {
  try {
    const res = await axios.get('/projects');
    dispatch({type: GET_PROJECTS, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: PROJECT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getProjectsWithCategory = () => async dispatch => {
  try {
    const res = await axios.get('/projects/category');
    dispatch({type: GET_PROJECTS, payload: res.data.data});
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg.message, 'error')));
    }
    dispatch({
      type: PROJECT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getProject = id => async dispatch => {
  try {
    const res = await axios.get(`/projects/${id}`);
    dispatch({type: GET_PROJECT, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: PROJECT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const updateProject = (formData, id) => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post(`/projects/update/${id}`, formData, reqConfig);
    dispatch({
      type: UPDATE_PROJECT,
      payload: res.data.data,
    });
    dispatch(setAlert('Project Updated', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROJECT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const deleteProject = id => async dispatch => {
  try {
    const reqConfig = setHeader();
    await axios.delete(`/projects/${id}`, null, reqConfig);
    dispatch({
      type: DELETE_PROJECT,
      payload: id,
    });
    dispatch(setAlert('Project Deleted', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROJECT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const addProject = formData => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post('/projects/add', formData, reqConfig);
    dispatch({
      type: ADD_PROJECT,
      payload: res.data.data,
    });
    dispatch(setAlert('Project Added', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROJECT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
