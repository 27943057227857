import React, {Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import config from '../config';
import Spinner from '../components/layout/Spinner';

const Landing = ({auth: {isAuthenticated, loading}}) => {
  // redirect if logged in
  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return loading ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <div className='jumbotron'>
        <h1 className='display-4'>{config.TITLE}!</h1>
        <p className='lead'>
          <b>Click</b>{' '}
          <a href={config.AUTH_URL + '?src=' + window.location.href}>here</a> to
          go to login page.
        </p>
      </div>
    </Fragment>
  );
};

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Landing);
