import axios from 'axios';
import {
  GET_USERS,
  GET_USER,
  USER_ERROR,
  CLEAR_USER_META,
  CLEAR_OFFICE,
  CLEAR_ADDRESS,
} from './types';

export const getUsers = () => async dispatch => {
  try {
    const res = await axios.get('/users-pub');
    dispatch({type: CLEAR_USER_META});
    dispatch({type: CLEAR_OFFICE});
    dispatch({type: CLEAR_ADDRESS});
    dispatch({type: GET_USERS, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: USER_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getUser = id => async dispatch => {
  try {
    const res = await axios.get(`/users-pub/${id}`);
    dispatch({type: GET_USER, payload: res.data.data});
    dispatch({type: CLEAR_USER_META});
  } catch (error) {
    dispatch({
      type: USER_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getUserMeta = id => async dispatch => {
  try {
    const res = await axios.get(`/users-pub/${id}/meta/`);
    dispatch({type: GET_USER, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: USER_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
