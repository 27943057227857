import {
  GET_TEAMS,
  GET_TEAM,
  ADD_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  TEAM_ERROR,
  CLEAR_TEAM,
} from '../actions/types';

const initialState = {
  team: {},
  teams: [],
  loading: true,
  error: {},
};

export default function(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_TEAMS:
      return {
        ...state,
        team: {},
        teams: payload,
        loading: false,
      };
    case GET_TEAM:
      return {
        ...state,
        team: payload,
        teams: [],
        loading: false,
      };
    case ADD_TEAM:
      return {
        ...state,
        team: {},
        teams: [payload, ...state.teams],
        loading: false,
      };
    case UPDATE_TEAM:
      return {
        ...state,
        team: payload,
        teams: [],
        loading: false,
      };
    case DELETE_TEAM:
      return {
        ...state,
        team: {},
        teams: state.teams.filter(team => team._id !== payload),
        loading: false,
      };
    case CLEAR_TEAM:
      return {
        ...state,
        team: {},
        teams: [],
        loading: false,
      };
    case TEAM_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
