import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

const UserItem = ({user: {_id, name, email, date_deleted}}) => {
  return date_deleted ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div key={_id} className='card mb-4'>
        <div className='card-body'>
          <h5 className='card-title'>{name}</h5>
          <h6 className='card-subtitle mb-2 text-muted'>{email}</h6>
          <p className='card-text text-muted' />
          <Link to={`/address/edit/${_id}`} className='btn btn-sm btn-success'>
            view
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default connect()(UserItem);
