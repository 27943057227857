import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {deleteProject} from '../../actions/projects';

const ProjectItem = ({
  auth,
  project: {_id, project_name, projectKey, category},
  deleteProject,
}) => {
  const onClick = e => {
    deleteProject(e.currentTarget.dataset.id);
  };

  return (
    <Fragment>
      <tr key={`${_id}`}>
        <td>
          {project_name} / {projectKey} -{' '}
          <span className='small text-muted'>{category.category_name}</span>
        </td>
        <td className='text-center'>
          <Link to={`/projects/${_id}`} className='btn btn-sm btn-success'>
            edit
          </Link>{' '}
          &nbsp;
          <button
            onClick={e => onClick(e)}
            data-id={_id}
            className='btn btn-sm btn-danger'
          >
            delete
          </button>
        </td>
      </tr>
    </Fragment>
  );
};

ProjectItem.propTypes = {
  auth: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  deleteProject: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {deleteProject})(ProjectItem);
