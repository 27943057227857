import {DISC_TOKEN} from '../actions/types';

const initialState = {
  token: '',
  loadingToken: true,
};

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case DISC_TOKEN:
      return {
        ...state,
        token: payload,
        loadingToken: false,
      };
    default:
      return state;
  }
}
