import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import config from '../../config';
import Can from '../routing/Can';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  parent: {
    fontWeight: 'bold',
  },
  subparent: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: '#0000008a',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const VerticalNav = ({ auth: { isAuthenticated, loading, user } }) => {
  const location = useLocation();
  const classes = useStyles();
  const [openGeneral, setOpenGeneral] = useState(true);
  const [openHr, setOpenHr] = useState(true);
  const [openRecruitment, setOpenRecruitment] = useState(false);
  const [openRecruitmentSettings, setOpenRecruitmentSettings] = useState(false);
  const [openPL, setOpenPL] = useState(false);
  const [openBook, setOpenBook] = useState(false);
  const [openSettings, setOpenSettings] = useState(true);
  const [openProfile, setOpenProfile] = useState(true);
  const [openAbsence, setOpenAbsence] = useState(true);
  const [openCover, setOpenCover] = useState(false);
  const [openDesignChallenges, setOpenDesignChallenges] = useState(false);
  const [openCoverSettings, setOpenCoverSettings] = useState(false);
  const [openDesignChallengeSettings, setOpenDesignChallengeSettings] =
    useState(false);

  const handleClick = (type) => {
    switch (type) {
      case 'general':
        setOpenGeneral(!openGeneral);
        break;
      case 'hr':
        setOpenHr(!openHr);
        break;
      case 'recruitment':
        setOpenRecruitment(!openRecruitment);
        break;
      case 'pl':
        setOpenPL(!openPL);
        break;
      case 'book':
        setOpenBook(!openBook);
        break;
      case 'settings':
        setOpenSettings(!openSettings);
        break;
      case 'profile':
        setOpenProfile(!openProfile);
        break;
      case 'absence':
        setOpenAbsence(!openAbsence);
        break;
      case 'cover':
        setOpenCover(!openCover);
        break;
      case 'recruitmentSettings':
        setOpenRecruitmentSettings(!openRecruitmentSettings);
        break;
      case 'coverSettings':
        setOpenCoverSettings(!openCoverSettings);
        break;
      case 'designChallenge':
        setOpenDesignChallenges(!openDesignChallenges);
        break;
      case 'designChallengeSettings':
        setOpenDesignChallengeSettings(!openDesignChallengeSettings);
        break;
      default:
        break;
    }
  };
  return (
    <Fragment>
      {!loading && (
        <Drawer
          className={classes.drawer}
          variant='permanent'
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List
              component='nav'
              aria-labelledby='nested-list-subheader'
              className={classes.root}
            >
              <ListItem
                selected={'/dashboard' === location.pathname}
                dense
                button
                component={Link}
                to='/dashboard'
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type='body2' className={classes.parent}>
                      Dashboard
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                dense
                button
                onClick={(e) => handleClick('general')}
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type='body2' className={classes.parent}>
                      General Tools
                    </Typography>
                  }
                />
                {openGeneral ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openGeneral} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItem
                    dense
                    button
                    className={classes.nested}
                    component='a'
                    href={config.EXT_URL.LINK_AWS}
                  >
                    <ListItemText primary='Link Shortener' />
                  </ListItem>
                  <ListItem dense button className={classes.nested} divider>
                    <ListItemText primary='PPP (Disabled)' />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem dense button onClick={(e) => handleClick('hr')} divider>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type='body2' className={classes.parent}>
                      Human Resources
                    </Typography>
                  }
                />
                {openHr ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openHr} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItem
                    dense
                    button
                    onClick={(e) => handleClick('absence')}
                    divider
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography type='body2' className={classes.subparent}>
                          Absence
                        </Typography>
                      }
                    />
                    {openAbsence ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openAbsence} timeout='auto' unmountOnExit>
                    <ListItem
                      dense
                      button
                      className={classes.nested}
                      component='a'
                      href={config.TAP_URL}
                    >
                      <ListItemText primary='Absence (Tap In Tools)' />
                    </ListItem>
                    <Can
                      role={user.role}
                      perform='peoplem:visit'
                      yes={() => (
                        <ListItem
                          dense
                          button
                          className={classes.nested}
                          component='a'
                          href={config.MANAGER_URL}
                          divider
                        >
                          <ListItemText primary='Team Absence' />
                        </ListItem>
                      )}
                      no={() => ''}
                    />
                  </Collapse>
                  <Can
                    role={user.role}
                    perform='disc:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('recruitment')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                type='body2'
                                className={classes.subparent}
                              >
                                Recruitment
                              </Typography>
                            }
                          />
                          {openRecruitment ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse
                          in={openRecruitment}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.JOIN_URL}dashboard`}
                          >
                            <ListItemText primary='Join' />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            divider
                            href={`${config.DISC_TEST_URL}?tko=jangansoktau`}
                          >
                            <ListItemText primary='DISC Test' />
                          </ListItem>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <ListItem
                    selected={'/address' === location.pathname}
                    dense
                    button
                    className={classes.nested}
                    component={Link}
                    to='/address'
                  >
                    <ListItemText primary='Address Listing' />
                  </ListItem>
                  <Can
                    role={user.role}
                    perform='kucing:visit'
                    yes={() => (
                      <ListItem
                        dense
                        button
                        className={classes.nested}
                        component='a'
                        href={config.KUCING_URL}
                        divider
                      >
                        {' '}
                        <ListItemText primary='List Pertanyaan Kucing' />
                      </ListItem>
                    )}
                    no={() => ''}
                  />
                </List>
              </Collapse>
              <ListItem dense button onClick={(e) => handleClick('pl')} divider>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type='body2' className={classes.parent}>
                      Paperlust Tools
                    </Typography>
                  }
                />
                {openPL ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openPL} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('cover')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                type='body2'
                                className={classes.subparent}
                              >
                                Order Metrics Calculation
                              </Typography>
                            }
                          />
                          {openCover ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openCover} timeout='auto' unmountOnExit>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.COVER_URL}dashboard`}
                          >
                            <ListItemText primary='Custom Order - Turn Over Time' />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.COVER_URL}cards`}
                            divider
                          >
                            <ListItemText primary='Custom Order - Cards' />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.COVER_URL}order-metrics`}
                          >
                            <ListItemText primary='Platfom Order - Cleansheet' />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.COVER_URL}extra-invoices`}
                          >
                            <ListItemText primary='Platfom Order - Extra Invoices' />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            divider
                            href={`${config.COVER_URL}order-issues`}
                          >
                            <ListItemText primary='Platfom Order - Order Issues' />
                          </ListItem>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('designChallenge')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                type='body2'
                                className={classes.subparent}
                              >
                                Designs Metrics Calculation
                              </Typography>
                            }
                          />
                          {openDesignChallenges ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openDesignChallenges}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.COVER_URL}design-challenges`}
                          >
                            <ListItemText primary='Design Challenge - Turn Over Time' />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.COVER_URL}design-challenges-cards`}
                            divider
                          >
                            <ListItemText primary='Design Challenge - Cards' />
                          </ListItem>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <ListItem
                        dense
                        button
                        className={classes.nested}
                        component='a'
                        href={config.REVIEW_URL}
                        divider
                      >
                        <ListItemText primary='Customer Review' />
                      </ListItem>
                    )}
                    no={() => ''}
                  />
                  <ListItem
                    dense
                    button
                    className={classes.nested}
                    component='a'
                    href={config.ZENTALK_URL}
                    divider
                  >
                    <ListItemText primary='Zendesk Talk Report Cleaner' />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                dense
                button
                onClick={(e) => handleClick('book')}
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type='body2' className={classes.parent}>
                      Bookkeeper Tools
                    </Typography>
                  }
                />
                {openBook ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openBook} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItem
                    dense
                    button
                    className={classes.nested}
                    component='a'
                    href={config.XERO_URL}
                    divider
                  >
                    <ListItemText primary='Xero Description Generator' />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                dense
                button
                onClick={(e) => handleClick('settings')}
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type='body2' className={classes.parent}>
                      Settings
                    </Typography>
                  }
                />
                {openSettings ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openSettings} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Can
                    role={user.role}
                    perform='disc:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('recruitmentSettings')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                type='body2'
                                className={classes.subparent}
                              >
                                Recruitment
                              </Typography>
                            }
                          />
                          {openRecruitmentSettings ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openRecruitmentSettings}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.JOIN_URL}positions`}
                          >
                            <ListItemText primary='Positions' />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            divider
                            href={`${config.JOIN_URL}questions`}
                          >
                            <ListItemText primary='Questions' />
                          </ListItem>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('coverSettings')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                type='body2'
                                className={classes.subparent}
                              >
                                Order Metrics Calculation
                              </Typography>
                            }
                          />
                          {openCoverSettings ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse
                          in={openCoverSettings}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.COVER_URL}lists`}
                          >
                            <ListItemText primary='Trello List' />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            divider
                            href={`${config.COVER_URL}configuration`}
                          >
                            <ListItemText primary='Configuration' />
                          </ListItem>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) =>
                            handleClick('designChallengeSettings')
                          }
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                type='body2'
                                className={classes.subparent}
                              >
                                Design Metrics Calculation
                              </Typography>
                            }
                          />
                          {openDesignChallengeSettings ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openDesignChallengeSettings}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.COVER_URL}design-challenges-lists`}
                          >
                            <ListItemText primary='Trello Lists' />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            className={classes.nested}
                            component='a'
                            href={`${config.COVER_URL}design-challenges-configuration`}
                            divider
                          >
                            <ListItemText primary='Configuration' />
                          </ListItem>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <ListItem
                    dense
                    className={classes.nested}
                    button
                    component='a'
                    href={`${config.PAGESPEED_URL}url`}
                    divider
                  >
                    <ListItemText primary='Pagespeed URL' />
                  </ListItem>
                  <Can
                    role={user.role}
                    perform='office:visit'
                    yes={() => (
                      <ListItem
                        selected={'/offices-ui' === location.pathname}
                        dense
                        button
                        className={classes.nested}
                        component={Link}
                        to='/offices-ui'
                      >
                        <ListItemText primary='Office' />
                      </ListItem>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='team:visit'
                    yes={() => (
                      <li className='nav-item'>
                        <ListItem
                          selected={'/team-ui' === location.pathname}
                          dense
                          button
                          className={classes.nested}
                          component={Link}
                          to='/team-ui'
                          divider
                        >
                          <ListItemText primary='Team' />
                        </ListItem>
                      </li>
                    )}
                    no={() => ''}
                  />
                </List>
              </Collapse>
              <ListItem
                dense
                button
                onClick={(e) => handleClick('profile')}
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type='body2' className={classes.parent}>
                      Profile
                    </Typography>
                  }
                />
                {openProfile ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openProfile} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItem
                    selected={'/profile' === location.pathname}
                    dense
                    button
                    className={classes.nested}
                    component={Link}
                    to='/profile'
                  >
                    <ListItemText primary='Profile' />
                  </ListItem>
                  <ListItem
                    dense
                    button
                    className={classes.nested}
                    component='a'
                    href={config.AUTH_URL + 'logout/'}
                  >
                    <ListItemText primary='Logout' />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </div>
        </Drawer>
      )}
    </Fragment>
  );
};

VerticalNav.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(VerticalNav);
