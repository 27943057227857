import axios from 'axios';
import {
  GET_PROJECTS_CAT,
  GET_PROJECT_CAT,
  ADD_PROJECT_CAT,
  UPDATE_PROJECT_CAT,
  PROJECT_CAT_ERROR,
  DELETE_PROJECT_CAT,
} from '../actions/types';
import {setAlert} from './alert';
import setHeader from '../utils/SetHeader';

// get projects
export const getProjectCategories = () => async dispatch => {
  try {
    const res = await axios.get('/categories');
    dispatch({type: GET_PROJECTS_CAT, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: PROJECT_CAT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

// get project
export const getProjectCategory = id => async dispatch => {
  try {
    const res = await axios.get(`/categories/${id}`);
    dispatch({type: GET_PROJECT_CAT, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: PROJECT_CAT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const addProjectCategory = formData => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post('/categories/add', formData, reqConfig);
    dispatch({
      type: ADD_PROJECT_CAT,
      payload: res.data.data,
    });
    dispatch(setAlert('Project Category Added', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROJECT_CAT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const updateProjectCategory = (formData, id) => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post(
      `/categories/update/${id}`,
      formData,
      reqConfig
    );
    dispatch({
      type: UPDATE_PROJECT_CAT,
      payload: res.data.data,
    });
    dispatch(setAlert('Project Category Updated', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROJECT_CAT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const deleteProjectCategory = id => async dispatch => {
  try {
    const reqConfig = setHeader();
    await axios.delete(`/categories/${id}`, null, reqConfig);
    dispatch({
      type: DELETE_PROJECT_CAT,
      payload: id,
    });
    dispatch(setAlert('Project Category Deleted', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PROJECT_CAT_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
