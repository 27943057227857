import React, {Fragment, useEffect} from 'react';
import {withRouter, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {getTeams} from '../../actions/team';
import TeamItem from './TeamItem';

const Teams = ({team: {teams, loading}, getTeams}) => {
  useEffect(() => {
    getTeams();
  }, [getTeams]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <h5 className='card-title'>Team</h5>
          <p className='card-text'>
            <b>Manage</b> the <i>Team</i> listed below or <b>add</b> new{' '}
            <i>Users</i> by clicking <i>Users</i> button.
          </p>
          <Link to='/team-ui/add' className='btn btn-sm btn-primary'>
            Add Item
          </Link>{' '}
          <Link to='/users-ui' className='btn btn-sm btn-info'>
            Users
          </Link>{' '}
        </div>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>Team Name</th>
            <th scope='col' className='text-center'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {teams.map(team => (
            <TeamItem key={team._id} team={team} />
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

Teams.propTypes = {
  getTeams: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  team: state.team,
});
export default connect(mapStateToProps, {getTeams})(withRouter(Teams));
