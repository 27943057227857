import React, {Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Spinner from '../layout/Spinner';
import UserItem from './UserItem';
import Downloads from '../../utils/Downloads';

import {getUsers, buildDownloadFile} from '../../actions/users';
import config from '../../config';

const Users = ({users: {users, loading}, getUsers, buildDownloadFile}) => {
  useEffect(() => {
    getUsers();
  }, [getUsers]);
  const onClick = e => {
    let url = e.currentTarget.dataset.url;
    buildDownloadFile();
    setTimeout(() => {
      Downloads.httpRequest(url, 'post').then(response =>
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'distance.csv');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
      );
    }, 3000);
  };
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card mb-4'>
        <div className='card-body'>
          <h5 className='card-title'>Users</h5>
          <p className='card-text'>
            <b>Manage</b> the <i>User</i> listed below.
          </p>
          <Link to='/users-ui/add' className='btn btn-sm btn-primary'>
            Add User
          </Link>{' '}
          <Link to='/team-ui/' className='btn btn-sm btn-secondary'>
            Back
          </Link>{' '}
          <button
            onClick={e => onClick(e)}
            data-url={config.BACKEND_URL + 'users/filedownload'}
            className='btn btn-sm btn-info'
          >
            download
          </button>
        </div>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>Name</th>
            <th scope='col' className='text-center'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <UserItem key={user._id} user={user} />
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  buildDownloadFile: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  users: state.users,
});

export default connect(mapStateToProps, {getUsers, buildDownloadFile})(Users);
