import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import './App.css';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';

import PrivateRoute from './components/routing/PrivateRoute';
import Header from './components/layout/Header';
import VerticalNav from './components/layout/VerticalNav';
import Alert from './components/layout/Alert';
import Landing from './components/Landing';
import Dashboard from './components/dashboard/Dashboard';
import Profile from './components/profile/Profile';
import Projects from './components/projects/Projects';
import AddProject from './components/projects/AddProject';
import ViewProject from './components/projects/ViewProject';
import ProjectCategories from './components/projectCategories/ProjectCategories';
import AddProjectCategory from './components/projectCategories/AddProjectCategory';
import ViewProjectCategory from './components/projectCategories/ViewProjectCategory';
import Teams from './components/team/Teams';
import AddTeam from './components/team/AddTeam';
import ViewTeam from './components/team/ViewTeam';
import Users from './components/users/Users';
import AddUser from './components/users/AddUser';
import ViewUser from './components/users/ViewUser';
import Offices from './components/offices/Offices';
import AddOffice from './components/offices/AddOffice';
import ViewOffice from './components/offices/ViewOffice';
import Logout from './components/profile/Logout';

import Address from './components/alamat/Dashboard';
import EditAddress from './components/alamat/EditAddress';

// Redux
import { Provider } from 'react-redux';
import store from './store';

if (window.location.href.includes('?tkn')) {
  let token = window.location.href.split('?tkn=');
  token = token[1];
  localStorage.setItem('token', token);
}

if (localStorage.token) {
  setAuthToken(localStorage.token);
  store.dispatch(loadUser());
} else {
  // window.location.href = config.AUTH_URL + '?src=' + window.location.href;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  const classes = useStyles();

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <div className={classes.root}>
            <CssBaseline />
            <Header />
            <VerticalNav />
            <main className={classes.content}>
              <Toolbar />
              <Alert />
              <PrivateRoute exact path='/' component={Landing} />
              <Switch>
                <PrivateRoute exact path='/address' component={Address} />
                <PrivateRoute
                  exact
                  path='/address/edit/:id'
                  component={EditAddress}
                />
                <PrivateRoute exact path='/dashboard' component={Dashboard} />
                <PrivateRoute exact path='/profile' component={Profile} />
                <PrivateRoute exact path='/projects' component={Projects} />
                <PrivateRoute
                  exact
                  path='/projects/add'
                  component={AddProject}
                />
                <PrivateRoute
                  exact
                  path='/projects/categories'
                  component={ProjectCategories}
                />
                <PrivateRoute
                  exact
                  path='/projects/categories/add'
                  component={AddProjectCategory}
                />
                <PrivateRoute
                  exact
                  path='/projects/categories/:id'
                  component={ViewProjectCategory}
                />
                <PrivateRoute
                  exact
                  path='/projects/:id'
                  component={ViewProject}
                />
                <PrivateRoute exact path='/team-ui' component={Teams} />
                <PrivateRoute exact path='/team-ui/add' component={AddTeam} />
                <PrivateRoute exact path='/team-ui/:id' component={ViewTeam} />
                <PrivateRoute exact path='/users-ui' component={Users} />
                <PrivateRoute exact path='/users-ui/add' component={AddUser} />
                <PrivateRoute exact path='/users-ui/:id' component={ViewUser} />
                <PrivateRoute exact path='/offices-ui' component={Offices} />
                <PrivateRoute
                  exact
                  path='/offices-ui/add'
                  component={AddOffice}
                />
                <PrivateRoute
                  exact
                  path='/offices-ui/:id'
                  component={ViewOffice}
                />
                <PrivateRoute exact path='/logout' component={Logout} />
              </Switch>
            </main>
          </div>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
